"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var GlobalsTranslation = [
    {
        en: {
            translations: {
                faqs: "FAQs",
                chatButton: "Chat With Us",
                discover: "Discover",
                musicians: "Musicians",
                creators: "Creators",
                brands: "Brands",
                influencers: "Influencers",
                loops: "Artist LOOPS",
                membersArea: "Members Area",
                forMusiciansMessage: "<span>Loop Fans</span> allows you to create recurring revenue streams with VIP Membership Clubs that offer exclusive benefits through NFTs",
                collections: "Collections",
                musicAwards: "Music Awards",
                collectionNote: "A collection is a series of digital art that gives you exclusive benefits",
                collectionBenefits: "Features and Benefits",
                getTicket: "Get Your Ticket",
                getMembership: "Get Membership",
                exploreCollection: "Explore Collection",
                nftClubOneLiner: "A VIP Membership Club that offers exclusive benefits through NFTs from",
                joinArtistLoop: "Join an Artist Loop",
                createLoop: "Create a Loop",
                //Login/Signup Modal
                loginTitle: "Loop Fans Member Login",
                signupTitle: "Join as Loop Fans Member",
                signupSubTitle: "Get free access to explore VIP Clubs and special benefits",
                signupCheckList: [
                    "Access exclusive content, media and courses",
                    "Be the first to hear about new launches",
                    "Get notified of bonuses, giveaways and comps",
                    "Receive discounts to tickets and merch",
                ],
            },
        },
        es: {
            translations: {
                faqs: "Preguntas Frecuentes",
                chatButton: "Chatea con nosotros",
                discover: "Descubre",
                musicians: "Músicos",
                creators: "Creadores",
                brands: "Marcas",
                influencers: "Influencers",
                membersArea: "Miembros",
                forMusiciansMessage: "<span>Loop Fans</span> te permite crear flujos de ingresos recurrentes con clubes de membresía VIP que ofrecen beneficios exclusivos a través de tecnología Web3",
                collections: "Colecciones",
                collectionNote: "Una colección es una serie de arte digitales que te brinda beneficios exclusivos",
                collectionBenefits: "Características y Beneficios",
                getTicket: "Adquiere tu ticket",
                getMembership: "Obtener Membresía",
                exploreCollection: "Explorar Colección",
                nftClubOneLiner: "Este VIP Club te conecta con el mejor contenido y beneficios de",
                //Login/Signup Modal
                loginTitle: "Inicio de sesión de miembro de Loop Fans",
                signupTitle: "Únete como miembro de Loop Fans",
                signupSubTitle: "Obtenga acceso gratuito para explorar clubes VIP y beneficios especiales",
                signupCheckList: [
                    "Acceda a contenido, medios y cursos exclusivos",
                    "Sé el primero en enterarte de los nuevos lanzamientos",
                    "Recibe notificaciones de bonificaciones, regalos y competiciones",
                    "Recibe descuentos en entradas y mercancía",
                ],
            },
        },
    },
];
exports.default = GlobalsTranslation;
